<template>
  <div class="flex items-center justify-center"
       :class="[{ 'absolute right-0': icon_pos === 'absolute', 'relative': icon_pos === 'relative' }]">
    <span
        @mouseenter="show = true"
        @mouseleave="show = false"
        class="cursor-pointer text-white text-sm relative"
        :class="[{ 'icon-copy': icon === 'svg/copy.svg' }]">
      <img :src="require('@/assets/' + icon)" alt="" :class="'no-select '+icon_size">
      <transition name="fade">
      <div
          v-if="show && !hide_hint"
          class="absolute z-10 p-2 text-sm text-white bg-gray-800 rounded shadow-lg no-select text-center"
          :class="[{
            'tx': pos === 'top',
            'ty': pos === 'left',
            'w-40': slotLength > 20,
            'w-28': slotLength <= 20,
           }]">
        <slot/>
      </div>
    </transition>
    </span>


  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'svg/info.svg'
    },
    pos: {
      type: String,
      default: 'top'
    },
    icon_pos: {
      type: String,
      default: 'absolute'
    },
    icon_size: {
      type: String,
      default: 'h-4'
    },
    hide_hint: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slotLength() {
      if (this.$slots.default) {
        return this.$slots.default()
            .map(vnode => vnode.children)
            .filter(text => typeof text === "string") // убираем элементы
            .join('').length;
      }
      return 0;
    }
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.icon-copy:active img {
  transform: scale(0.85);
}

.ty {
  transform: translate(calc(-100% - 8px), calc(-50% - 8px))
}
.tx {
  transform: translate(calc(-50% + 8px), calc(-100% - 24px))
}
</style>