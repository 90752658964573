<template>
  <transition-group>

    <!--bucket-->
    <div v-if="isBucket || isPopup || temp.popup_err" id="overlay"
         @click="isBucket = false; isPopup = false; delete(temp.popup_err); $router.push('/')"
         class="bucket-overlay"></div>
    <div v-if="isBucket" class="fixed inset-0 z-50 flex justify-center items-center pointer-events-none">
      <div class="p-4 bg-white rounded shadow-lg pointer-events-auto select-none w-5/6 max-w-lg relative">
        <h1 class="text-left mb-1 pl-0">Bucket</h1>
        <button
            class="hover:text-gray-800 pr-1 font-black text-gray-700 absolute top-4 right-4 w-min"
            @click="isBucket = false">×
        </button>
        <div class="alert-info text-sm" v-if="bucket.length > 0 && $store.getters.getConnectionStatus">
          <span>📋</span><span>You can send up to <b>{{ $store.getters.getMaxMessages }} txs</b> at once.<br>The earliest will be processed first.</span>
        </div>
        <div class="alert-danger text-sm mt-2" v-if="bucket.length > 0">
          <span>🤷‍♀️</span><span><b>Attention!</b> Bucket is an experimental thing. #DYOR #NFA</span>
        </div>
        <p v-if="bucket.length === 0" class="p-5">¯\_(ツ)_/¯</p>
        <div v-for="(item, index) in bucket" :key="index" class="py-2 border-b">
          <div class="flex justify-around">
            <span class="text-left">{{ tabs[item.tab].forms[item.formId].name }}</span>
            <span class="flex-grow border-dotted border-b-3 border-black mx-2"></span>
            <span class="flex justify-end gap-1">
            <input type="number" v-model="bucket[index].times" step="1" min="1"
                   class="border border-gray-300 px-1 rounded focus:outline-none focus:ring focus:border-purple-100 w-fit"
                   style="width: 55px;"
                   placeholder="times" required>
                        <button
                            class="hover:text-gray-800 pl-2 pr-1 text-gray-700 text-sm w-min font-black"
                            @click="bucket.splice(index,1)">×
            </button>
          </span>
          </div>
          <div class="w-full py-4" v-if="tabs[item.tab].forms[item.formId].from">⚠️
            <b>{{ tabs[item.tab].forms[item.formId].from }}</b> suggests sending this tx. <br>Check the <span
                class="bg-sky-500 hover:bg-sky-600 py-1 px-2 whitespace-nowrap text-white cursor-pointer"
                style="transform: scale(0.85); display: inline-block;" @click="isBucket = false">tx body</span> and
            don’t send it if unsure.
          </div>
        </div>
        <div>
          <div class="alert-error relative" v-if="temp.bucketErr && temp.bucketErr.length > 0">
            <button
                class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded  px-2 absolute right-2 top-2 text-white"
                @click="delete(temp.bucketErr)">×
            </button>
            <span>⚠️</span> <span><b>Error:</b></span>
            <div v-for="err in temp.bucketErr" :key="err">{{ err }}</div>
          </div>
        </div>
        <div class="grid gap-4 grid-cols-3 lg:grid-cols-5 mt-2" v-if="bucket.length > 0">
          <div :class="!$store.getters.getConnectionStatus ? 'hidden lg:block' : ''"></div>
          <div class="hidden lg:block"></div>
          <div v-if="$store.getters.getConnectionStatus" class="hidden lg:block"></div>
          <button type="button" @click="bucket = []; isBucket = false"
                  class="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600">Dump
          </button>
          <button v-if="$store.getters.getConnectionStatus" type="button" @click="sendBucket()"
                  class="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600">Send
          </button>
          <button v-if="!$store.getters.getConnectionStatus" type="button" @click="sendBucket()"
                  class="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 col-span-2">Connect TON
          </button>
        </div>
      </div>
    </div>
    <div class="fixed bottom-4 right-4 z-40" v-if="!isBucket">
      <button @click="openBucket()"
              class="bg-purple-500 text-white p-2 px-4 font-bold rounded hover:bg-purple-600 h-fit shadow-lg flex justify-center items-center gap-2">
        <img :src="require('@/assets/svg/bucket.svg')" alt="" class="no-select h-3">
        <span>Bucket{{ bucketCount }}</span>
      </button>
    </div>
    <!--bucket-->


    <!--popup-->
    <div v-if="isPopup" class="fixed inset-0 z-50 flex justify-center items-center pointer-events-none">
      <div class="p-4 bg-white rounded shadow-lg pointer-events-auto select-none w-5/6 max-w-lg relative">
        <h1 class="text-left mb-1 pl-0">Open new tab</h1>
        <button
            class="hover:text-gray-800 pr-1 text-gray-700 absolute top-4 right-4 w-min"
            @click="isPopup = false">×
        </button>
        <div class="alert-danger text-sm mt-2 mb-2">
          <span>🤷‍♀️</span><span><b>Attention!</b> If you don't understand what it's for, don't use it. #DYOR</span>
        </div>
        <div class="alert-error relative mb-2" v-if="temp.popup_error">
          <button
              class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded  px-2 absolute right-2 top-2 text-white"
              @click="delete(temp.popup_error)">×
          </button>
          <span>⚠️</span> <span><b>Error:</b></span>
          <div>{{ temp.popup_error }}</div>
        </div>
        <form @submit.prevent="proxyAddTab()" class="w-full">
          <div class="grid grid-cols-6 gap-2">
            <input type="text" v-model="temp.popup_val" ref="popup_val_ref"
                   class="template-input col-span-5"
                   placeholder="Enter the key"
                   required>
            <button type="submit" class="lg:hidden bg-green-500 p-2 rounded hover:bg-green-600 text-white font-bold">+
            </button>
            <button type="submit"
                    class="hidden lg:block bg-green-500 p-2 rounded hover:bg-green-600 text-white font-bold">Add
            </button>
          </div>
        </form>
      </div>
    </div>
    <!--popup-->

    <!--popup_err-->
    <div v-if="temp.popup_err" class="fixed inset-0 z-50 flex justify-center items-center pointer-events-none">
      <div class="p-4 bg-white rounded shadow-lg pointer-events-auto select-none min-w-1/4 max-w-80 lg:max-w-lg relative">
        <h1 class="text-left mb-1 pl-0">Error</h1>
        <button
            class="hover:text-gray-800 pr-1 text-gray-700 absolute top-4 right-4 w-min"
            @click="delete(temp.popup_err)">×
        </button>
        <p class="m-2 text-justify">
          {{ temp.popup_err }}
        </p>
        <div class="flex justify-center items-center">
          <button @click="delete(temp.popup_err)"
                  class="block bg-slate-500 p-2 py-1 rounded hover:bg-slate-600 text-white text-sm font-bold">OK
          </button>
        </div>
      </div>
    </div>
    <!--popup_err-->

  </transition-group>

  <div id="main" class="pb-16 pl-4 pr-4 bg-blue-50">
    <div class="container mx-auto grid grid-cols-1 lg:grid-cols-2">
      <div>
        <div class="container mx-auto grid grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
          <div class="alert-info">
            <span>📋</span><span><b>Hint:</b> use <b>amount(), toNano(N)</b> in storeCoins, <b>now()</b> in storeUint and <b>myAddress()</b> in storeAddress.</span>
          </div>
          <div class="alert-danger">
            <span>🛸</span><span><b>Attention:</b> use <b>act</b> on your own risk and only for testing purposes.</span>
          </div>
        </div>

        <div class="container mx-auto grid grid-cols-3 lg:grid-cols-4 gap-4 mb-4" id="tabsAlert">
          <div class="alert-info">
            <span>🎨</span><span><b>API:</b> now you can add tabs if you have a key. Need key? <a class="font-bold"
                                                                                                 href="https://t.me/qowboy"
                                                                                                 target="_blank">t.me/qowboy</a>.
            <span class="inline-block"><icon-hint style="margin-left: 4.2px;" icon_pos="relative" icon="svg/info-w.svg"
                                                  icon_size="h-3" pos="left">So far, it only works for well-established and reliable projects.</icon-hint></span></span>
          </div>
        </div>
        <!-- tabs -->
        <div class="container h-fit mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4" id="tabs">
          <div class="grid grid-cols-4">
            <button @click="setTab('local')" :class="current_tab == 'local' ? 'opacity-1 bg-slate-600' : 'opacity-90'"
                    class="bg-slate-500 p-2 hover:bg-slate-600 text-white col-span-3 text-sm">local
            </button>
            <button @click="openPopup()" class="bg-green-500 p-2 hover:bg-green-600 text-white font-bold">+</button>
          </div>
          <transition-group name="fade">
            <div v-for="(tab,key) in filteredTabs" :key="key" class="grid grid-cols-4">
              <button @click="setTab(key)"
                      :class="current_tab == key ? 'opacity-1 bg-slate-600' : 'opacity-90'"
                      class="bg-slate-500 text-white text-sm p-2 hover:bg-slate-600 col-span-3">
                {{ key.length > 8 ? key.substring(0, 4) + '..' + key.substring(key.length - 4) : key }}
                <span v-if="tab.secret_key">🔑</span><span v-else>👀</span></button>
              <button @click="closeTab(key)" class="bg-red-500 p-2 hover:bg-red-600 text-white font-bold">×</button>
            </div>
          </transition-group>
        </div>
        <div v-if="temp.top_error || temp.top_info"
             class="container mx-auto grid grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
          <div class="alert-error relative" v-if="temp.top_error">
            <button
                class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded  px-2 absolute right-2 top-2 text-white"
                @click="delete(temp.top_error)">×
            </button>
            <span>⚠️</span> <span><b>Error:</b></span>
            <div>{{ temp.top_error }}</div>
          </div>
          <div class="alert-success relative" v-if="temp.top_info">
            <button
                class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded  px-2 absolute right-2 top-2 text-white"
                @click="delete(temp.top_info)">×
            </button>
            <span>📰</span> <span><b>Info:</b></span>
            <div>{{ temp.top_info }}</div>
          </div>
        </div>
        <transition-group name="fade">

          <div class="container mx-auto grid grid-cols-3 lg:grid-cols-4 gap-4 mb-4"
               v-if="tabs[current_tab].secret_key || current_tab === 'local'">
            <div class="alert-info">
              <span>👨🏻‍🎨</span><span><b>Creative Mode:</b> you can add hints, extra info and doc links to your tx board. </span>
            </div>
          </div>
          <div class="container h-fit mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4"
               v-if="tabs[current_tab].secret_key || current_tab === 'local'">
            <button @click="creative_mode = !creative_mode"
                    :class="creative_mode ? 'from-orange-700 to-orange-500' : 'from-green-500 to-green-700'"
                    class="flex opacity-90 hover:opacity-100 justify-center gap-2 px-4 py-2 text-sm col-span-2 font-semibold text-white transition bg-gradient-to-r shadow-md  active:scale-90">
              <img :src="require('@/assets/svg/cube.svg')" alt="" class="no-select h-4"> Creative Mode:
              {{ creative_mode ? 'enable' : 'disable' }}
            </button>
          </div>

        </transition-group>
        <!-- /tabs -->


        <div class="container h-fit mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
          <button @click="addForm()" class="bg-purple-500 text-white p-2 rounded hover:bg-purple-600">+ Action</button>
          <button @click="saveForms()"
                  :class="(tabs[current_tab] && tabs[current_tab].secret_key) || current_tab === 'local' ? '' : 'opacity-70 hover:bg-green-500'"
                  :disabled="!((tabs[current_tab] && tabs[current_tab].secret_key) || current_tab === 'local')"
                  class="bg-green-500 text-white p-2 rounded hover:bg-green-600">Save tab
          </button>
          <button @click="resetAll()" class="bg-red-500 text-white p-2 rounded hover:bg-red-600">Reset tab</button>
          <button @click="show_templates = !show_templates"
                  class="bg-yellow-500 col-span-1 text-white p-2 rounded hover:bg-yellow-600">Templates <span
              v-if="show_templates">▲</span> <span v-else>▼</span>
          </button>
        </div>
        <div v-show="show_templates" class="container h-fit mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4">

          <button @click="addForm('nftTransfer')"
                  class="bg-indigo-500 hover:bg-indigo-600 template-btn">
            <img :src="require('@/assets/svg/punk.svg')" alt="" class="no-select h-4"> <span>Send NFT</span>
          </button>
          <button @click="addForm('jettonTransfer')"
                  class="bg-slate-700 hover:bg-slate-800 template-btn">
            <img :src="require('@/assets/svg/bolt.svg')" alt="" class="no-select h-4"> <span>Send Jetton</span>
          </button>
          <button @click="addForm('dedustSwap')"
                  class="bg-orange-500 hover:bg-orange-600 template-btn">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>TON to DUST</span>
          </button>
          <button @click="addForm('dedustSwapScaleTon')"
                  class="bg-orange-500 hover:bg-orange-600 template-btn">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>DUST to TON</span>
          </button>
          <button @click="addForm('dedustSwapTonPunk')"
                  class="bg-orange-500 hover:bg-orange-600 template-btn">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>TON to PUNK</span>
          </button>
          <button @click="addForm('dedustSwapPunkTon')"
                  class="bg-orange-500 hover:bg-orange-600 template-btn">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>PUNK to TON</span>
          </button>
          <button @click="addForm('stonSwapTONPunk')"
                  class="bg-stone-700  hover:bg-stone-800 template-btn">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>TON to PUNK</span>
          </button>
          <button @click="addForm('stonSwapPunkTON')"
                  class="bg-stone-700  hover:bg-stone-800 template-btn">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>PUNK to TON</span>
          </button>
          <button @click="addForm('stonSwapTONSpng')"
                  class="bg-stone-700  hover:bg-stone-800 template-btn">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>TON to SPNG</span>
          </button>
          <button @click="addForm('stonSwapSpngTON')"
                  class="bg-stone-700  hover:bg-stone-800 template-btn">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>SPNG to TON</span>
          </button>
          <button @click="addForm('BigPumpBuy')"
                  class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-blue-500 hover:to-cyan-500 template-btn">
            <img :src="require('@/assets/svg/w95.svg')" alt="" class="no-select h-5"> <span>Buy on BigBump</span>
          </button>
          <button @click="addForm('BigPumpSell')"
                  class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-blue-500 hover:to-cyan-500 template-btn">
            <img :src="require('@/assets/svg/w95.svg')" alt="" class="no-select h-5"> <span>Sell on BigPump</span>
          </button>
          <button @click="addForm('restartDNS')"
                  class="bg-blue-500 hover:bg-blue-600 template-btn">
            <img :src="require('@/assets/svg/auction.svg')" alt="" class="no-select h-4"> <span>Restart DNS</span>
          </button>
        </div>
      </div>
      <div class="hidden lg:flex lg:flex-col justify-start items-center select-none">
        <span>sub <a href="https://t.me/ghwnd" class="underline text-pink-700"
                     target="_blank">@ghwnd</a> or not idk</span>
        <div>also visit <a href="https://t.me/tondev" class="underline text-pink-700" target="_blank">@tondev</a> <a
            href="https://t.me/tondev_eng" class="underline text-pink-700" target="_blank">@tondev_eng</a> <a
            href="https://t.me/tactlang" class="underline text-pink-700" target="_blank">@tactlang</a></div>
      </div>
    </div>
    <div class="container mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-6">

      <div v-for="(form,i) in currentForms" :key="i" class="bg-white p-4 rounded-xl shadow-xl relative h-fit">
        <div class="manage grid grid-cols-4 lg:grid-cols-12 top-3 right-3 gap-4"
             :class="form.show ? 'lg:mb-6 mb-2' : ''">
          <h3 class="lg:text-left text-md order-last lg:order-first col-span-4 lg:col-span-8 font-semibold">Action:
            {{ form.name }}</h3>
          <div class="col-span-4 grid grid-cols-4">
            <icon-hint icon_pos="relative" @click="hideForm(i)" pos="top"
                       :icon="form.show ? 'svg/minimize.svg' : 'svg/maximize.svg'">
              {{ form.show ? 'Hide form' : 'Show form' }}
            </icon-hint>
            <icon-hint icon_pos="relative" @click="renameForm(i)" pos="top" icon="svg/edit.svg">Edit title</icon-hint>
            <icon-hint icon_pos="relative" @click="addForm(i,true)" pos="top" icon="svg/duplicate.svg">Duplicate form
            </icon-hint>
            <icon-hint icon_pos="relative" @click="delForm(i)" pos="left" :hide_hint="true" icon="svg/close.svg">Close
              form
            </icon-hint>
          </div>
        </div>
        <form @submit.prevent="sendForm(i)" v-show="form.show">

          <div v-if="form.read_before !== undefined" class="alert-danger mb-2">
            <span>🧐</span><span><b>Attention:</b> {{ form.read_before }}</span></div>
          <div v-if="typeof(form.type) !== 'undefined' && ['dedust_swap_from_j', 'transfer_j'].includes(form.type)"
               class="grid col-span-10 lg:grid-cols-12 mb-2">
            <div class="grid grid-cols-10 lg:grid-cols-7 lg:col-span-7 col-span-10">
              <input
                  v-model="tabs[current_tab].forms[i].jetton" type="text"
                  class="template-input lg:col-span-6 col-span-9"
                  placeholder="Jetton master"
                  required>
              <icon-hint icon_pos="relative" pos="left">Jetton master (jetton address)</icon-hint>
            </div>
            <div class="grid grid-cols-10 col-span-10 lg:grid-cols-5 lg:col-span-5 mt-2 lg:mt-0">
              <button
                  class="bg-emerald-500 col-span-9 lg:col-span-4 text-white p-2 rounded hover:bg-emerald-600 lg:mb-0"
                  type="button" @click="fetchUserJettonWalletAddress(form.jetton,i)">Get my jetton wallet
              </button>
              <icon-hint icon_pos="relative" pos="left">Get your jetton wallet address</icon-hint>
            </div>
          </div>
          <div v-if="typeof(form.type) !== 'undefined' && ['with_dns'].includes(form.type)">
            <div class="alert-danger mb-2">
              <span>⚠️</span><span><b>Attention:</b> use the field below <b>only</b> to get the domain NFT address, <b>not the wallet address</b>.</span>
            </div>
            <div
                class="grid col-span-10 lg:grid-cols-12 mb-2">

              <div class="grid grid-cols-10 lg:grid-cols-7 lg:col-span-7 col-span-10">
                <input
                    v-model="tabs[current_tab].forms[i].item_name" type="text"
                    class="template-input lg:col-span-6 col-span-9 transition-all"
                    placeholder="Plain DNS (long.ton)"
                    @keydown.enter.prevent="fetchDNSNFTAddress(form.item_name,i)"
                >
                <icon-hint icon_pos="relative" pos="left">Human-readable domain name</icon-hint>
              </div>
              <div class="grid grid-cols-10 col-span-10 lg:grid-cols-5 lg:col-span-5 mt-2 lg:mt-0">
                <button
                    class="bg-emerald-500 col-span-9 lg:col-span-4 text-white p-2 rounded hover:bg-emerald-600 lg:mb-0"
                    type="button" @click="fetchDNSNFTAddress(form.item_name,i)">Get DNS NFT address
                </button>
                <icon-hint icon_pos="relative" pos="left">Get domain name NFT Address</icon-hint>
              </div>
            </div>
          </div>
          <div v-if="is_creative" class="mb-2 justify-start flex gap-2">
            <button type="button" @click="addDescription(i, 'recipient')"
                    class="bg-orange-500 w-1/2 lg:w-fit text-white p-2 rounded hover:bg-orange-600 flex gap-2 justify-center items-center">
              <img :src="require('@/assets/svg/pen.svg')" alt="" class="no-select h-3"><span>desc: recipient</span>
            </button>
            <button type="button" @click="addDescription(i, 'amount')"
                    class="bg-orange-500 w-1/2 lg:w-fit text-white p-2 rounded hover:bg-orange-600 flex gap-2 justify-center items-center">
              <img :src="require('@/assets/svg/pen.svg')" alt="" class="no-select h-3"><span>desc: amount</span>
            </button>
          </div>
          <div class="grid mb-2 grid-cols-10 lg:grid-cols-12">
            <input type="text" v-model="tabs[current_tab].forms[i].address"
                   class="template-input"
                   :class="typeof(form.address_hint) !== 'undefined' ? 'col-span-9 lg:col-span-11' : 'col-span-10 lg:col-span-12'"
                   placeholder="Contract Address"
                   required>
            <icon-hint icon_pos="relative" pos="left" v-if="typeof(form.address_hint) !== 'undefined'">
              {{ form.address_hint }}
            </icon-hint>
          </div>
          <div class="grid mb-2 grid-cols-10 lg:grid-cols-12">
            <input type="number" v-model="tabs[current_tab].forms[i].amount" step="0.000000001"
                   class="template-input"
                   :class="typeof(form.amount_hint) !== 'undefined' ? 'col-span-8 lg:col-span-10' : 'col-span-9 lg:col-span-11'"
                   placeholder="Amount (in TON)" required>
            <span class="flex justify-center items-center no-select">💎</span>
            <icon-hint icon_pos="relative" pos="left" v-if="typeof(form.amount_hint) !== 'undefined'">
              {{ form.amount_hint }}
            </icon-hint>
          </div>
          <div class="w-full flex justify-center">
            <h3 class="font-medium rounded p-2 border-fuchsia-800 bg-fuchsia-400 mb-2 text-white w-full"
                v-if="Object.keys(tabs[current_tab].forms[i].cells).length > 0">Cells</h3></div>
          <div v-for="(cell,name) in tabs[current_tab].forms[i].cells" class="relative" :key="name">
            <div class="manage grid grid-cols-10 lg:grid-cols-12 gap-4 mb-2">
              <span
                  class="cellName col-span-6 p-2 pl-3 pr-3 lg:col-span-8 text-sm w-fit bg-purple-500 border-purple-800 rounded text-white font-medium relative">Cell: {{
                  name
                }}
              </span>
              <icon-hint icon_pos="relative" @click="hideCell(i,name)" pos="top"
                         :icon="cell.minimized === true ? 'svg/maximize.svg' : 'svg/minimize.svg'">
                {{ cell.minimized === true ? 'Show cell' : 'Hide cell' }}
              </icon-hint>
              <icon-hint icon_pos="relative" @click="renameCell(i,name)" pos="top" icon="svg/edit.svg">Edit title
              </icon-hint>
              <icon-hint icon_pos="relative" @click="cloneCell(i, name)" pos="top" icon="svg/duplicate.svg">Duplicate
                cell
              </icon-hint>
              <icon-hint icon_pos="relative" @click="delCell(i,name)" pos="left" :hide_hint="true" icon="svg/close.svg">
                Remove cell
              </icon-hint>
            </div>

            <div v-show="cell.minimized !== true">
              <div
                  class="grid grid-cols-3 md:grid-cols-3 mb-2 gap-4 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
                <div></div>
                <div></div>
                <button type="button" @click="addLine(i, name)"
                        class="bg-green-500 text-white p-2 rounded hover:bg-green-600">+store
                </button>

              </div>

              <div v-for="(line,j) in cell.lines" :key="j"
                   class="grid grid-cols-3 md:grid-cols-6 mb-2 gap-4 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
                <div class="linetype grid grid-cols-7 col-span-2 items-center relative">
                  <div class="flex col-span-2 ml-1.5 justify-left">
                    <button v-if="j != 0"
                            type="button"
                            :class="is_creative ? 'rounded-r-none' : ''"
                            class="bg-red-500 text-white pl-2 min-w-8 pr-2 rounded hover:bg-red-600 h-7"
                            @click="removeLine(i,name,j)">×
                    </button>
                    <button v-if="is_creative" type="button" @click="addDescription(i,name,j)"
                            :class="j != 0 ? 'rounded-l-none' : ''"
                            class="bg-orange-500 text-white pl-1 pr-1 min-w-8 rounded hover:bg-orange-600 h-7 flex justify-center items-center">
                      <img :src="require('@/assets/svg/pen.svg')" alt="" class="no-select h-3">
                    </button>
                  </div>
                  <div class="text-sm"
                       :class="j == 0 || (typeof(cell[j-1]) !== 'undefined' && cell[j-1].type === 'endCell') ? 'col-span-4' : 'col-span-4'">
                  <span
                      v-if="j == 0 || (typeof(cell[j-1]) !== 'undefined' && cell[j-1].type === 'endCell')">beginCell()</span>.{{
                      line.type
                    }}<span
                      v-if="line.type === 'endCell'">()</span>
                  </div>
                  <icon-hint icon_pos="absolute" pos="top" v-if="typeof(line.hint) !== 'undefined'">{{
                      line.hint
                    }}
                  </icon-hint>

                </div>
                <select v-if="line.type !== 'endCell'"
                        class="template-select col-span-1"
                        v-model="tabs[current_tab].forms[i].cells[name].lines[j].subType" :name="'line_select_' + j">
                  <option selected value="Uint">Uint</option>
                  <option value="Address">Address</option>
                  <option value="Coins">Coins</option>
                  <option value="Bit">Bit</option>
                  <option value="Int">Int</option>
                  <option value="StringTail">StringTail</option>
                  <option value="Ref">Ref</option>
                  <option value="RefString">Ref (base64)</option>
                </select>
                <input v-if="line.type !== 'endCell' && line.subType !== 'Ref'"
                       v-model="tabs[current_tab].forms[i].cells[name].lines[j].value" type="text"
                       class="template-input w-full"
                       :class="['Int','Uint'].includes(line.subType) ? 'col-span-2' : 'col-span-3'" placeholder="value"
                       required>
                <input v-if="['Int','Uint'].includes(line.subType)"
                       v-model="tabs[current_tab].forms[i].cells[name].lines[j].bits"
                       type="number"
                       class="template-input w-full col-span-1"
                       placeholder="bits" required>
                <select v-if="line.subType === 'Ref'"
                        class="template-select col-span-3"
                        v-model="tabs[current_tab].forms[i].cells[name].lines[j].value"
                        :name="'line_select_ref_' + j">
                  <option v-for="(cell,cellName) in tabs[current_tab].forms[i].cells" :key="cellName" :value="cellName">
                    {{
                      cellName
                    }}
                  </option>
                </select>
              </div>

              <div
                  class="grid grid-cols-3 md:grid-cols-6 mb-2 gap-4 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
                <div class="linetype col-span-2 grid grid-cols-7 relative text-sm">
                  <span class="col-span-2"></span>
                  <span class="col-span-4">.endCell()</span>
                  <icon-hint @click="copyCell(i, name)" pos="top" icon="svg/copy.svg">Copy this cell (base64)
                  </icon-hint>
                </div>
              </div>
            </div>
            <div class="container p-2 bg-red-400 border-red-600 rounded text-white mb-2 relative"
                 v-if="temp.copyCellErrors[i] && temp.copyCellErrors[i][name] && temp.copyCellErrors[i][name].length > 0">
              <div v-for="error in temp.copyCellErrors[i][name]" class="p-1 break-all text-left" :key="error">• {{
                  error
                }}
              </div>
              <button
                  class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded p-1 px-2 absolute right-2 top-2 text-white"
                  @click="delete(temp.copyCellErrors[i])">×
              </button>
            </div>
          </div>
          <div
              v-if="ifCells(i)"
              class="grid gap-4 grid-cols-2 mb-2 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
            <div class="p-2 no-select">Cell to send:</div>
            <select
                class="border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-purple-100"
                v-model="tabs[current_tab].forms[i].cellToSend"
                :name="'line_cell_to_send'">
              <option v-for="(cell,name) in tabs[current_tab].forms[i].cells" :key="name" :value="name">{{
                  name
                }}
              </option>
            </select>
          </div>
          <div class="container p-2 bg-red-400 border-red-600 rounded text-white mb-2 relative"
               v-if="form.errors && form.errors.length > 0">
            <div v-for="error in form.errors" class="p-1 break-all text-left" :key="error">• {{ error }}</div>
            <button
                class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded px-2 absolute right-2 top-2 text-white"
                @click="delete(tabs[current_tab].forms[i].errors)">×
            </button>
          </div>
          <div class="container p-2 bg-blue-400 border-blue-600 rounded text-white mb-2 relative"
               v-if="form.shareLink">
            <div class="text-left px-2 py-1 select-none">Just tap on the link to copy:</div>
            <div class="break-all cursor-pointer font-bold w-fit text-left text-sm px-2 py-1 select-none"
                 @click="copyShare(form.shareLink, i)">{{ form.shareLink }}
              <span v-if="form.copied" class="font-extrabold">Copied!</span>
            </div>
            <button
                class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded px-2 absolute right-2 top-2 text-white"
                @click="delete(tabs[current_tab].forms[i].shareLink)">×
            </button>
          </div>
          <div class="grid gap-4 grid-cols-3">
            <div class="grid grid-cols-5 gap-2">
              <div v-if="typeof(form.doclink) === 'undefined' && !is_creative" class="col-span-5"></div>
              <div v-if="is_creative" :class="{
                    'col-span-2 lg:col-span-1': is_creative && typeof(form.doclink) !== 'undefined',
                    'col-span-5': is_creative && typeof(form.doclink) == 'undefined',
                    'hidden': !is_creative,
                  }"
                   @click="addDescription(i,'doclink')"
                   class="bg-orange-500 text-white p-2 cursor-pointer rounded hover:bg-orange-600 select-none flex gap-2 justify-center items-center">
                <img :src="require('@/assets/svg/pen.svg')" alt="" class="no-select h-3">
                {{ typeof (form.doclink) == 'undefined' ? 'doclink' : '' }}
              </div>
              <a :href="form.doclink" target="_blank" v-if="typeof(form.doclink) !== 'undefined'"
                 :class="is_creative ? 'col-span-3 lg:col-span-4' : 'col-span-5'"
                 class="bg-purple-500 text-white p-2 rounded hover:bg-purple-600 select-none">Docs
              </a>
            </div>
            <button type="button" @click="addLine(i, tabs[current_tab].forms[i].cellCursor, 'store', 'Uint', true)"
                    class="bg-green-500 text-white p-2 rounded hover:bg-green-600">New cell
            </button>
            <div class="grid grid-cols-5 lg:grid-cols-4">
              <button type="submit" class="bg-blue-500 text-white p-2 col-span-3 rounded-l-md hover:bg-blue-600">Send
              </button>
              <button type="button" @click="toBucket(i)"
                      class="bg-purple-500 text-white p-2 col-span-2 lg:col-span-1 rounded-r-md font-bold hover:bg-purple-600 flex justify-center items-center">
                <img :src="require('@/assets/svg/bucket.svg')" alt="" class="no-select h-3">
              </button>
            </div>
          </div>

          <div v-if="tabs[current_tab].secret_key" class="grid gap-4 grid-cols-3 mt-2">
            <div></div>
            <div></div>
            <button type="button" @click="share(i)"
                    class="bg-sky-500 text-white p-2 rounded hover:bg-sky-600">Share ➦
            </button>
          </div>
        </form>
      </div>


    </div>


  </div>
</template>

<script>


import {Address, beginCell, Cell} from "@ton/ton";
import IconHint from "@/components/IconHint";
import templates from "@/templates/templates";

const math = require('mathjs');

export default {
  name: 'HomeView',
  components: {IconHint},
  data() {
    return {
      show_templates: false,
      templates: templates,
      current_tab: 'local',
      creative_mode: false,
      tabs: {
        local: {
          forms: {
            0: {
              name: 'Send qq to sd',
              show: true,
              address: 'UQD_5Gn0KWVVr1nXWuNB_ZJlAWjAdI8LNqO_HTNH7ijeW_R_',
              amount: '1',
              cellToSend: 'textComment',
              cellCursor: 'textComment',
              typeCursor: null,
              cells: {
                "textComment": {
                  minimized: false,
                  locked: false,
                  lines: {
                    "0": {
                      "type": "store", "subType": "Uint", "value": "0", "bits": 32
                    },
                    "1": {
                      "type": "store",
                      "subType": "StringTail",
                      "value": "qq, sd",
                      'hint': 'Here: text comment, also known as "memo".'
                    }
                  }
                },
              }
            },
          }
        },
      },
      bucket: [],
      isBucket: false,
      isPopup: false,
      temp: {
        popup_val: '',
        errors: [],
        cells: {},
        copyCellErrors: {}
      }
    }
  },
  methods: {
    hideForm(i) {
      this.tabs[this.current_tab].forms[i].show = !this.tabs[this.current_tab].forms[i].show
    },
    setTab(key) {
      this.current_tab = key
    },
    toBucket(formId, fromShared = false) {
      const form = this.tabs[this.current_tab].forms[formId];
      if(fromShared) {
        let errors = [];
        try { Address.parse(form.address) } catch (e) { errors.push(e) }
        if(form.amount.length === 0) errors.push('amount required');
        if(errors.length > 0) this.isBucket = false;
        else this.isBucket = true;
      }
      if (form.uniq === 'undefined') form.uniq = this.generateRandomHash()
      this.bucket.push({
        tab: this.current_tab,
        times: 1,
        formId: formId,
        uniq: form.uniq
      })
    },
    async share(i) {
      const form = JSON.stringify(this.tabs[this.current_tab].forms[i])
      const key = this.tabs[this.current_tab].secret_key;
      const response = await fetch(`${this.$store.getters.getAPIUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${key}`
        },
        body: form
      })
      if (!response.ok) {
        this.scrollToTabs('some api error');
        return null;
      }
      const data = await response.json();
      if (data.result === true) {
        this.tabs[this.current_tab].forms[i].shareLink = window.location + data.link
      } else {
        this.scrollToTabs(data.message)
      }

    },
    saveForms() {
      const forms = JSON.stringify(this.tabs[this.current_tab].forms)
      if (this.current_tab === 'local') {
        localStorage.setItem('actions', forms);
      } else {
        const key = this.tabs[this.current_tab].secret_key; // Значение GET-параметра

        fetch(`${this.$store.getters.getAPIUrl}`, { // Добавляем key в URL
          method: "PUT",
          headers: {"Content-Type": "application/json", "Authorization": "Bearer " + key},
          body: forms // Отправляем JSON
        })
            .then(response => {
              if (!response.ok) {
                this.scrollToTabs('some api error')
              }
              return response.json();
            })
            .then(data => {
              if (data.result === true) {
                this.scrollToTabs(data.message, 'info')
              } else {
                this.scrollToTabs(data.message)
              }
            })
            .catch(error => console.error('api error:', error));
      }
    },
    generateRandomHash(length = 16) {
      const array = new Uint8Array(length);
      window.crypto.getRandomValues(array);
      return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
    },
    async copyShare(link, formId) {
      this.tabs[this.current_tab].forms[formId].copied = true
      setTimeout(() => {
        this.tabs[this.current_tab].forms[formId].copied = false
      }, 1000)
      return await navigator.clipboard.writeText(link);
    },
    async copyCell(formId, cellName) {
      this.temp.cells = [];
      this.temp.errors = [];
      this.temp.copyCellErrors = [];
      let cell = this.buildCell(formId, cellName, true, this.current_tab)
      if (this.temp.errors.length > 0) {
        this.temp.copyCellErrors[formId] = {}
        return this.temp.copyCellErrors[formId][cellName] = this.temp.errors;
      }
      const toCopy = cell.endCell().toBoc().toString("base64")
      return await navigator.clipboard.writeText(toCopy);
    },
    buildCell(formId, cellName, fromCopy = false, tab = 'local') {
      let form = this.tabs[tab].forms[formId]
      let errors = [];
      let amount = form['amount'] * 10 ** 9
      let cell = form.cells[cellName].lines
      console.log('cell', cell, fromCopy)

      let updatedExpression
      let buildedCell = beginCell();
      console.log(cellName)
      Object.keys(cell).forEach((id) => {
        const line = cell[id]
        if (line.type === 'store') {
          console.log(line.type, line.subType, line.value, line.bits)
          try {
            switch (line.subType) {
              case 'Address': {
                let addr;
                addr = line.value
                if (line.value === 'myAddress()') addr = this.$store.getters.getUserAddress;
                try {
                  addr = Address.parse(addr)
                } catch (e) {
                  return errors.push('Invalid address: ' + line.value)
                }
                console.log(addr)
                return buildedCell.storeAddress(addr)
              }
              case 'Uint': {
                const now = Math.floor(Date.now() / 1000);
                updatedExpression = line.value.replace(/now\(\)/g, now);
                try {
                  const result = math.evaluate(updatedExpression);
                  console.log(result, parseInt(line.bits))
                  return buildedCell.storeUint(result, parseInt(line.bits))
                } catch (error) {
                  errors.push('Err in evaluate')
                  return null;
                }
              }
              case 'StringTail':
                return buildedCell.storeStringTail(line.value)
              case 'Ref':
                if (line.value !== cellName && typeof (this.temp.cells[line.value]) !== 'undefined') {
                  return buildedCell.storeRef(this.temp.cells[line.value])
                } else if (line.value !== cellName && typeof (this.temp.cells[line.value]) === 'undefined') {
                  let buildRefCell = this.buildCell(formId, line.value, true, tab)
                  return buildedCell.storeRef(buildRefCell)
                } else return errors.push('Invalid ref: ' + line.value);
              case 'RefString': {
                let cellFromB64
                try {
                  cellFromB64 = Cell.fromBase64(line.value)
                } catch (e) {
                  return errors.push('storeRef ' + e)
                }
                return buildedCell.storeRef(cellFromB64)
              }
              case 'Coins': {
                updatedExpression = line.value.replace(/amount\(\)/g, amount);
                const regex = /toNano\((\d+(\.\d+)?)\)/g;

                const replacedInput = updatedExpression.replace(regex, (match, p1) => {
                  return (parseFloat(p1) * 1e9).toString();
                });
                try {
                  const result = math.evaluate(replacedInput);
                  console.log(result)
                  return buildedCell.storeCoins(result)
                } catch (error) {
                  errors.push('Error in evaluate ' + line.value)
                  return null;
                }
              }
              case 'Bit':
                console.log(parseInt(line.value))
                return buildedCell.storeBit(parseInt(line.value))
              case 'Int':
                console.log(parseInt(line.value), parseInt(line.bits))
                return buildedCell.storeInt(parseInt(line.value), parseInt(line.bits))
              default:
                return console.log('error', line.type);
            }
          } catch (e) {
            errors.push(e)
          }
        }
      })
      console.log('these errors', errors)
      this.temp.errors = this.temp.errors.concat(errors)
      this.temp.cells[cellName] = buildedCell
      return buildedCell;
    },
    async sendBucket() {
      this.temp.errors = [];
      this.temp.cells = [];
      this.temp.bucketErr = [];
      const tonConnectUI = this.$store.getters.getTonConnect

      if (!this.$store.getters.getConnectionStatus) {
        return tonConnectUI.openModal();
      }
      let messages = [];
      let counter_bucket_to_splice = 0
      let counter_txs = 0
      let reduce_bucket = {iterator: 0, count: 0}
      for (let j = 0; j < this.$store.getters.getMaxMessages; j++) {
        if (this.bucket[j]) {
          let i = this.bucket[j].formId;
          const tab = this.bucket[j].tab;
          let form;
          form = this.tabs[tab].forms[i]
          try { Address.parse(form.address) } catch (e) { this.temp.errors.push('Invalid receiver. ' + e) }
          console.log('form', form)
          let cells = [];
          Object.keys(form.cells).forEach(name => {
            cells[name] = this.buildCell(i, name, false, tab);
          })
          console.log('cells', cells)
          if (this.temp.errors.length > 0) {
            this.temp.bucketErr.push('Check ' + this.tabs[tab].forms[i].name + ' form')
            return this.tabs[tab].forms[i].errors = this.temp.errors;
          }
          let payload = null;
          if (typeof (cells[this.tabs[tab].forms[i].cellToSend]) !== 'undefined') {
            const cellToSend = cells[this.tabs[tab].forms[i].cellToSend].endCell();
            console.log('cellToSend:', cellToSend)
            payload = cellToSend.toBoc().toString("base64")
          }
          console.log('boc', payload)
          let message = {
            address: this.tabs[tab].forms[i].address,
            amount: this.tabs[tab].forms[i].amount * 1000000000
          }
          if (payload != null) message.payload = payload
          const remainingCapacity = this.$store.getters.getMaxMessages - counter_txs;
          const messagesToAdd = Math.min(this.bucket[j].times, remainingCapacity);

          messages.push(...Array(messagesToAdd).fill(message));

          let new_txs = messagesToAdd;
          counter_txs += new_txs
          if (new_txs < this.bucket[j].times) {
            reduce_bucket.iterator = j;
            reduce_bucket.count = new_txs;
            break;
          } else counter_bucket_to_splice++
        } else break;
      }
      console.log('messages', messages)
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 3600,
        messages: messages
      }
      console.log('transaction', transaction)
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
        console.log('bucky', this.bucket, reduce_bucket, reduce_bucket.iterator)
        this.bucket[reduce_bucket.iterator].times -= reduce_bucket.count
        this.bucket.splice(0, counter_bucket_to_splice)
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    async sendForm(i) {
      this.temp.errors = [];
      this.temp.cells = [];
      const tonConnectUI = this.$store.getters.getTonConnect
      if (!this.$store.getters.getConnectionStatus) {
        return tonConnectUI.openModal()
      }
      let form = this.tabs[this.current_tab].forms[i]
      try { Address.parse(form.address) } catch (e) { this.temp.errors.push('Invalid receiver. ' + e) }
      let cells = [];
      Object.keys(form.cells).forEach(name => {
        cells[name] = this.buildCell(i, name, false, this.current_tab);
      })
      if (this.temp.errors.length > 0) {
        return this.tabs[this.current_tab].forms[i].errors = this.temp.errors;
      }
      let payload = null;
      if (typeof (cells[this.tabs[this.current_tab].forms[i].cellToSend]) !== 'undefined') {
        const cellToSend = cells[this.tabs[this.current_tab].forms[i].cellToSend].endCell();
        console.log('cellToSend:', cellToSend)
        payload = cellToSend.toBoc().toString("base64")
      }
      console.log('boc', payload)
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 3600,
        messages: [
          {
            address: this.tabs[this.current_tab].forms[i].address,
            amount: this.tabs[this.current_tab].forms[i].amount * 1000000000,
            payload: payload
          }
        ]
      }
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    addLine(formId, cellName = 'body', type = 'store', subType = 'Uint', newCell = false) {
      if (this.tabs[this.current_tab].forms[formId].typeCursor === 'endCell' || (newCell && Object.keys(this.tabs[this.current_tab].forms[formId].cells).length > 0)) {
        cellName = 'body_' + this.generateRandomHash().substring(0, 3);
      }
      this.tabs[this.current_tab].forms[formId].cellCursor = cellName;
      this.tabs[this.current_tab].forms[formId].typeCursor = type;
      if (typeof (this.tabs[this.current_tab].forms[formId].cells[cellName]) === 'undefined') this.tabs[this.current_tab].forms[formId].cells[cellName] = {lines: {}};
      return this.tabs[this.current_tab].forms[formId].cells[cellName].lines[Object.entries(this.tabs[this.current_tab].forms[formId].cells[cellName].lines).length] = {
        type: type,
        subType: subType,
        value: ''
      };
    },
    removeLine(formId, cellName, lineId) {
      delete (this.tabs[this.current_tab].forms[formId].cells[cellName].lines[lineId])
      const values = Object.values(this.tabs[this.current_tab].forms[formId].cells[cellName].lines);
      this.tabs[this.current_tab].forms[formId].cells[cellName].lines = values.reduce((acc, item, index) => {
        acc[index] = item;
        return acc;
      }, {});
    },
    delForm(formId) {
      delete (this.tabs[this.current_tab].forms[formId])
      const values = Object.values(this.tabs[this.current_tab].forms);
      this.tabs[this.current_tab].forms = values.reduce((acc, item, index) => {
        acc[index] = item;
        return acc;
      }, {});
      this.bucket = this.bucket.filter(element => {
        // Проверяем, совпадает ли uniq текущей формы с uniq элемента
        if (this.tabs[this.current_tab].forms[element.formId]?.uniq !== element.uniq) {
          // Ищем форму с matching uniq
          const matchingForm = Object.entries(this.tabs[this.current_tab].forms).find(([, form]) =>
              form.uniq === element.uniq
          );
          if (matchingForm) {
            // Если нашли форму с таким же uniq, обновляем formId
            element.formId = matchingForm[0];
            return true;
          }
          // Если форма с таким uniq не найдена, удаляем элемент
          return false;
        }
        // Если uniq совпадает с текущей формой, оставляем элемент
        return true;
      });
      console.log(this.tabs)
    },
    addForm(template = 'default', fromExist = false) {
      let form;
      if (!fromExist) form = JSON.parse(JSON.stringify(this.templates[template]))
      else form = JSON.parse(JSON.stringify(this.tabs[this.current_tab].forms[template]))
      for (let key in form.cells) {
        const cell = form.cells[key];
        if (!Object.prototype.hasOwnProperty.call(cell, 'lines')) {
          form.cells[key] = {lines: cell};
        }
      }
      form.uniq = this.generateRandomHash()
      if (!this.tabs[this.current_tab].forms) {
        this.tabs[this.current_tab].forms = {}; // Создаём пустой объект, если его нет
      }
      this.tabs[this.current_tab].forms[Object.keys(this.tabs[this.current_tab].forms).length] = form
    },
    ifCells(formId) {
      return Object.keys(this.tabs[this.current_tab].forms[formId].cells).length > 0;
    },
    renameForm(i) {
      const name = prompt("Enter new name (1-32 chars):");
      if (name.length > 0 && name.length < 32) {
        this.tabs[this.current_tab].forms[i].name = name;
      }
    },
    renameCell(formId, cellName) {
      const name = prompt("Enter new name (1-18 chars):");
      if (name.length > 0 && name.length < 18 && name !== cellName) {
        const cell = this.tabs[this.current_tab].forms[formId].cells[cellName];
        this.tabs[this.current_tab].forms[formId].cells[name] = cell
        this.delCell(formId, cellName)
      }
    },
    isValidPrompt(prompt) {
      return /^[a-zа-яA-ZА-Я0-9.,:_\-() ]+$/.test(prompt);
    },
    isValidDNS(prompt) {
      return /^[a-zа-я0-9-.]+$/.test(prompt) && prompt.length >= 4 && prompt.length <= 126;
    },
    isValidKey(prompt) {
      return /^[a-zA-Z0-9_]+$/.test(prompt);
    },
    isValidURL(url) {
      return /^(https?:\/\/)[a-zA-Zа-яА-Я0-9.-]+(:\d+)?(\/[a-zA-Zа-яА-Я0-9.,:/?#&=_-]*)?$/.test(url);
    },
    addDescription(formId, fieldName, i = 0) {
      const form = this.tabs[this.current_tab].forms[formId];
      if (fieldName === 'recipient') {
        const hint = prompt("Enter the description (a-zA-Zа-яА-Я0-9:.,):");
        if (hint.length > 2 && this.isValidPrompt(hint)) form.address_hint = hint;
        else delete (form.address_hint)
      } else if (fieldName === 'doclink') {
        const hint = prompt("Enter docs URL:");
        if (hint.length > 2 && this.isValidURL(hint)) form.doclink = hint;
        else delete (form.doclink)
      } else if (fieldName === 'amount') {
        const hint = prompt("Enter the description (a-zA-Zа-яА-Я:.,):");
        if (hint.length > 2 && this.isValidPrompt(hint)) form.amount_hint = hint;
        else delete (form.amount_hint)
      } else {
        const hint = prompt("Enter the description (a-zA-Zа-яА-Я:.,):");
        if (hint.length > 2 && this.isValidPrompt(hint))
          this.tabs[this.current_tab].forms[formId].cells[fieldName].lines[i].hint = hint
        else delete (this.tabs[this.current_tab].forms[formId].cells[fieldName].lines[i].hint)
      }
    },
    hideCell(formId, cellName) {
      this.tabs[this.current_tab].forms[formId].cells[cellName].minimized = !this.tabs[this.current_tab].forms[formId].cells[cellName].minimized
    },
    cloneCell(formId, cellName) {
      this.tabs[this.current_tab].forms[formId].cells[cellName + '_' + this.generateRandomHash().substring(0, 3)] = structuredClone(this.tabs[this.current_tab].forms[formId].cells[cellName])
    },
    delCell(formId, cellName) {
      delete (this.tabs[this.current_tab].forms[formId].cells[cellName]);
    },
    resetAll() {
      this.tabs[this.current_tab].forms = {
        0: this.templates.send_qq,
      }
      this.bucket = []
    },
    async fetchUserJettonWalletAddress(jetton, formId) {
      const tonConnectUI = this.$store.getters.getTonConnect
      if (!this.$store.getters.getConnectionStatus) {
        return tonConnectUI.openModal()
      }
      if (jetton.length < 32) return this.temp.popup_err = 'Enter the address of jetton master contract.'
      try {
        const address = this.$store.getters.getUserAddress;
        if (address == null) {
          return null;
        }
        let result = await this.runContractMethod(
            jetton,
            'get_wallet_address',
            [{type: "slice", cell: beginCell().storeAddress(Address.parse(address)).endCell()}]
        );
        if (!result.result[0] || (result.result[0].type && result.result[0].type !== 'slice')) {
          throw new Error('Invalid jetton master');
        }
        const userWalletAddress = result.result[0].cell.asSlice().loadAddress().toString();
        this.tabs[this.current_tab].forms[formId].address = userWalletAddress
      } catch (error) {
        this.scrollToTabs(error)
      }
    },
    async fetchDNSNFTAddress(dns, formId) {
      dns = dns.endsWith('.ton') ? dns.slice(0, -4) : dns
      dns = dns.toLowerCase().trim()
      if (!this.isValidDNS(dns)) return this.temp.popup_err = 'Incorrect domain name. It must be 4-126 characters long and follow the format: [a-z0-9-].'
      // const dnsCell = beginCell().storeStringTail(dns).endCell()
      const index = await this.getNftIndex(dns)
      console.log(index)
      try {
        let result = await this.runContractMethod(
            "EQC3dNlesgVD8YbAazcauIrXBPfiVhMMr5YYk2in0Mtsz0Bz",
            'get_nft_address_by_index',
            [{type: "int", value: index}]
        );
        console.log(result)
        if (!result.result[0] || (result.result[0].type && result.result[0].type !== 'slice')) {
          throw new Error('Invalid item name');
        }
        const address = result.result[0].cell.asSlice().loadAddress().toString();
        this.tabs[this.current_tab].forms[formId].address = address
        const amountMap = {
          4: 100, 5: 50, 6: 40, 7: 30,
          8: 20, 9: 10, 10: 5
        };
        this.tabs[this.current_tab].forms[formId].amount = amountMap[dns.length] ?? (dns.length > 10 ? 1 : '');
      } catch (error) {
        this.scrollToTabs(error)
      }
    },
    openBucket() {
      console.log(this.bucket)
      this.isBucket = true
    },
    async loadTabs() {
      const savedTabs = localStorage.getItem("tabs");
      if (savedTabs) {
        const tabObj = JSON.parse(savedTabs).sort((a, b) => a.length - b.length);
        for (const e of tabObj) {
          this.addTab(e, false)
          await new Promise(resolve => setTimeout(resolve, 500));
        }
      }
    },
    async loadShared() {
      if (this.$route.params.sharedKey) {
        console.log('try')
        let data = null;
        if (this.isValidKey(this.$route.params.sharedKey) && (this.$route.params.sharedKey.slice(0, 3) === 'sh_' || this.$route.params.sharedKey.length < 16)) data = await this.addTab(this.$route.params.sharedKey, false)
        if (data != null && data.result === true && this.$route.params.sharedKey.slice(0, 3) === 'sh_') {
          this.setTab(this.$route.params.sharedKey)
          this.toBucket(0, true)
        } else if (data != null && data.result === true && this.$route.params.sharedKey.length < 16) {
          this.setTab(this.$route.params.sharedKey)
          this.$router.push('/')
        } else {
          this.$router.push('/')
        }
      }
    },
    openPopup() {
      this.isPopup = true;
      this.$nextTick(() => {
        this.$refs.popup_val_ref.focus()
      });
    },
    async proxyAddTab() {
      delete (this.temp.popup_error)
      const val = this.temp.popup_val
      const test = this.isValidKey(val)
      if (test) {
        const res = await this.addTab(val, true)
        if (res.error) {
          this.temp.popup_val = ''
          return this.temp.popup_error = res.error
        } else {
          this.temp.popup_val = ''
          delete (this.temp.popup_error)
          this.isPopup = false;
        }
      } else {
        this.temp.popup_val = ''
        return this.temp.popup_error = "incorrect key format: a-zA-Z0-9."
      }
    },
    async addTab(key = null, withAlert = true) {
      if (key === null) key = prompt("Enter the key:").toLowerCase();
      if (key === null) return;
      key = key.toLowerCase();
      const api = this.$store.getters.getAPIUrl;
      const type = key.length >= 32 ? 'Bearer' : 'Public'
      const response = await fetch(api, {
        method: "GET",
        headers: {
          "Authorization": `${type} ${key}`
        }
      });
      if (!response.ok) {
        if (withAlert) return {error: 'some api error, try again later'};//this.scrollToTabs('some api error')
        return null;
      }
      const data = await response.json();
      if (data.result === true) {
        const forms = this.sanitizeObject(data.data)
        const public_key = data.public_key ? data.public_key : null;
        this.tabs[public_key] = {};
        this.tabs[public_key].forms = forms;
        if (key !== public_key) {
          this.tabs[public_key].secret_key = key
          this.removeTabFromStorage(public_key)
        }
        if (withAlert) this.setTab(public_key);
        if (key.slice(0, 3) !== 'sh_') {
          const savedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
          const saveKey = key !== public_key ? key : public_key;
          if (!savedTabs.includes(saveKey)) savedTabs.push(saveKey);
          localStorage.setItem("tabs", JSON.stringify(savedTabs));
        }
        return data;
      } else {
        this.removeTabFromStorage(key)
        if (withAlert) return {error: data.message};
      }
      return null;
    },
    removeTabFromStorage(key) {
      const savedTabs = JSON.parse(localStorage.getItem("tabs") || "[]"); // Загружаем актуальные данные
      const newTabs = savedTabs.filter(tab => key !== tab); // Фильтруем, исключая вкладку
      localStorage.setItem("tabs", JSON.stringify(newTabs)); // Сохраняем обновленный массив
    },
    closeTab(name) {
      const tab = this.current_tab;

      if (tab === name) this.current_tab = 'local'
      let subKey = name;
      if (this.tabs[name].secret_key !== null) {
        subKey = this.tabs[name].secret_key
        delete this.tabs[subKey];
        this.removeTabFromStorage(subKey)
      }
      if (this.$route.params.sharedKey && (this.$route.params.sharedKey === name || this.$route.params.sharedKey === subKey)) {
        this.$router.push('/');
      }
      this.bucket = this.bucket.filter((e) => e.tab !== name && e.tab !== subKey)
      delete this.tabs[name];
      this.removeTabFromStorage(name)
    },
    scrollToTabs(text, type = 'error') {
      delete (this.temp.top_error)
      delete (this.temp.top_info)
      setTimeout(() => {
        if (type === 'error') this.temp.top_error = text
        else this.temp.top_info = text
        const anchor = document.getElementById('tabsAlert');
        if (anchor) {
          anchor.scrollIntoView({behavior: 'smooth'});
        }
      }, 150)
    }
  },
  computed: {
    bucketCount() {
      const count = this.bucket.reduce((sum, e) => sum + e.times, 0);
      return count > 0 ? ` (${count})` : '';
    },
    currentForms() {
      return this.tabs[this.current_tab] ? this.tabs[this.current_tab].forms : {};
    },
    filteredTabs() {
      return Object.fromEntries(
          Object.entries(this.tabs).filter(([key]) => key !== 'local')
      );
    },
    is_creative() {
      return (this.tabs[this.current_tab].secret_key || this.current_tab === 'local') && this.creative_mode;
    },
  },
  watch: {
    '$route.params.sharedKey': {
      immediate: true, // Запускаем при загрузке
      handler(newKey) {
        if (newKey && newKey.length > 2) {
          this.loadShared()
        }
      }
    },
  },
  mounted() {
    let saved = localStorage.getItem("actions")
    if (saved != null) {
      let data = JSON.parse(saved)
      for (let form in data) {
        if (data[form].uniq === undefined) data[form].uniq = this.generateRandomHash()
        for (let key in data[form].cells) {
          const cell = data[form].cells[key];
          if (!Object.prototype.hasOwnProperty.call(cell, 'lines')) {
            data[form].cells[key] = {lines: cell};
          }
        }
      }
      this.tabs.local.forms = data;
    }
    this.loadTabs()
  },
}
</script>
<style>    .masonry {
  column-count: 2;
  column-gap: 1rem;
}

.masonry-item {
  break-inside: avoid;
  margin-bottom: 1rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>