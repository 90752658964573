const templates = {
    default: {
        name: 'New message (tx)',
        show: true,
        address: '',
        amount: '',
        cellToSend: 'body',
        cellCursor: 'body',
        typeCursor: null,
        cells: {}
    },
    send_qq: {
        "name": "Send qq to sd",
        "show": true,
        "address": "UQD_5Gn0KWVVr1nXWuNB_ZJlAWjAdI8LNqO_HTNH7ijeW_R_",
        "amount": "1",
        "cellToSend": "textComment",
        "cellCursor": "textComment",
        "typeCursor": null,
        "cells": {
            "textComment": {
                "minimized": false,
                "locked": false,
                "lines": {
                    "0": {
                        "type": "store", "subType": "Uint", "value": "0", "bits": 32
                    },
                    "1": {
                        "type": "store",
                        "subType": "StringTail",
                        "value": "qq, sd",
                        "hint": "Here: text comment, also known as memo."
                    }
                }
            }
        }
    },
    jettonTransfer: {
        "name": "Transfer Jetton",
        "type": "transfer_j",
        "jetton": "",
        'doclink': 'https://github.com/ton-blockchain/TEPs/blob/master/text/0074-jettons-standard.md',
        "show": true,
        "address": "",
        'address_hint': 'Here: user\'s jetton wallet address',
        "amount": '',
        "cellToSend": "body",
        "cellCursor": "body",
        "typeCursor": "store",
        "cells": {
            "forwardPayload": {
                "0": {"type": "store", "subType": "Uint", "value": "0", "bits": 32},
                "1": {
                    "type": "store",
                    "subType": "StringTail",
                    "value": "",
                    'hint': 'Here: Text comment (also known as "memo").'
                }
            },
            "body": {
                "0": {"type": "store", "subType": "Uint", "value": "0x0f8a7ea5", "bits": 32},
                "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                "2": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "",
                    'hint': 'Amount of transferred jettons in elementary units.'
                },
                "3": {
                    "type": "store",
                    "subType": "Address",
                    "value": "",
                    'hint': 'Address of the new owner of the jettons.'
                },
                "4": {
                    "type": "store",
                    "subType": "Address",
                    "value": "",
                    'hint': 'Address where to send a response with confirmation of a successful transfer and the rest of the incoming message Toncoins.'
                },
                "5": {"type": "store", "subType": "Bit", "value": "0"},
                "6": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "",
                    'hint': 'The amount of nanotons to be sent to the destination address.'
                },
                "7": {"type": "store", "subType": "Bit", "value": "1"},
                "8": {"type": "store", "subType": "Ref", "value": "forwardPayload"}
            }
        }
    },
    restartDNS: {
        "name": "Restart DNS Auction",
        "type": "with_dns",
        "item_name": "",
        'doclink': 'https://github.com/ton-blockchain/dns-contract/blob/main/func/nft-item.fc#L242',
        "show": true,
        "address": "",
        'address_hint': 'Here: DNS NFT address',
        "amount": '',
        "cellToSend": "body",
        "cellCursor": "body",
        "typeCursor": "store",
        "cells": {
            "body": {
                "0": {"type": "store", "subType": "Uint", "value": "0x4ed14b65", "bits": 32},
                "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
            }
        }
    },
    nftTransfer: {
        "name": "Send NFT",
        'doclink': 'https://github.com/ton-blockchain/TEPs/blob/master/text/0062-nft-standard.md',
        "show": true,
        "address": "",
        'address_hint': 'Here: NFT address',
        "amount": "",
        "cellToSend": "body",
        "cellCursor": "body",
        "typeCursor": "store",
        "cells": {
            "body": {
                "0": {"type": "store", "subType": "Uint", "value": "0x5fcc3d14", "bits": 32},
                "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                "2": {
                    "type": "store",
                    "subType": "Address",
                    "value": "",
                    'hint': 'Address of the new owner of the NFT item.'
                },
                "3": {
                    "type": "store",
                    "subType": "Address",
                    "value": "",
                    'hint': 'Address where to send a response with confirmation of a successful transfer and the rest of the incoming message coins.'
                },
                "4": {"type": "store", "subType": "Uint", "value": "0", "bits": 1},
                "5": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "",
                    'hint': 'The amount of nanotons to be sent to the new owner.'
                },
                "6": {"type": "store", "subType": "Bit", "value": "0"}
            }
        }
    },
    'dedustSwap': {
        "name": "Swap TON to DUST",
        "type": "dedust_swap",
        'doclink': 'https://docs.dedust.io/recipes',
        "show": true,
        "address": "EQDa4VOnTYlLvDJ0gZjNYm5PXfSmmtL6Vs6A_CZEtXCNICq_",
        'address_hint': 'Here: TON vault address',
        "amount": 1,
        "cellToSend": "body",
        "cellCursor": "message",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "0": {
                    "type": "store",
                    "subType": "Uint",
                    "value": "now() + 5 * 60",
                    "bits": 32,
                    'hint': 'Specifies a deadline for the swap. If the swap reaches the Pool after this time, it will be rejected.'
                },
                "1": {
                    "type": "store",
                    "subType": "Address",
                    "value": "myAddress()",
                    'hint': 'Specifies an address where funds will be sent after the swap. Default: sender\'s address.'
                },
                "2": {"type": "store", "subType": "Uint", "value": "0", "bits": 2},
                "3": {"type": "store", "subType": "Bit", "value": "0"},
                "4": {"type": "store", "subType": "Bit", "value": "0"}
            },
            "body": {
                "0": {"type": "store", "subType": "Uint", "value": "0xea06185d", "bits": 32},
                "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                "2": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "amount() - toNano(0.2)",
                    'hint': 'TON amount for the swap except fee'
                },
                "3": {
                    "type": "store",
                    "subType": "Address",
                    "value": "EQDcm06RlreuMurm-yik9WbL6kI617B77OrSRF_ZjoCYFuny",
                    'hint': 'Pool address. Here: TON/DUST pool'
                },
                "4": {"type": "store", "subType": "Uint", "value": "0", "bits": 1},
                "5": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "0",
                    'hint': 'Minimum output of the swap. If the actual value is less than specified, the swap will be rejected.'
                },
                "6": {"type": "store", "subType": "Bit", "value": "0"},
                "7": {"type": "store", "subType": "Ref", "value": "swap_params"}
            },
        }
    },
    'dedustSwapTonPunk': {
        "name": "Swap TON to PUNK",
        "type": "dedust_swap",
        'doclink': 'https://docs.dedust.io/recipes',
        "show": true,
        "address": "EQDa4VOnTYlLvDJ0gZjNYm5PXfSmmtL6Vs6A_CZEtXCNICq_",
        'address_hint': 'Here: TON vault address',
        "amount": 1,
        "cellToSend": "body",
        "cellCursor": "message",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "0": {
                    "type": "store",
                    "subType": "Uint",
                    "value": "now() + 5 * 60",
                    "bits": 32,
                    'hint': 'Specifies a deadline for the swap. If the swap reaches the Pool after this time, it will be rejected.'
                },
                "1": {
                    "type": "store",
                    "subType": "Address",
                    "value": "myAddress()",
                    'hint': 'Specifies an address where funds will be sent after the swap. Default: sender\'s address.'
                },
                "2": {"type": "store", "subType": "Uint", "value": "0", "bits": 2},
                "3": {"type": "store", "subType": "Bit", "value": "0"},
                "4": {"type": "store", "subType": "Bit", "value": "0"}
            },
            "body": {
                "0": {"type": "store", "subType": "Uint", "value": "0xea06185d", "bits": 32},
                "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                "2": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "amount() - toNano(0.2)",
                    'hint': 'TON amount for the swap except fee'
                },
                "3": {
                    "type": "store",
                    "subType": "Address",
                    "value": "EQDQdqlfuYhkgInJfHYV5KEI7YF4SPE2mduYiCrBIQeQXJWC",
                    'hint': 'Pool address. Here: TON/PUNK pool'
                },
                "4": {"type": "store", "subType": "Uint", "value": "0", "bits": 1},
                "5": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "0",
                    'hint': 'Minimum output of the swap. If the actual value is less than specified, the swap will be rejected.'
                },
                "6": {"type": "store", "subType": "Bit", "value": "0"},
                "7": {"type": "store", "subType": "Ref", "value": "swap_params"}
            },
        }
    },
    'dedustSwapScaleTon': {
        "name": "Swap DUST to TON",
        "type": "dedust_swap_from_j",
        "jetton": "EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE",
        'doclink': 'https://docs.dedust.io/recipes',
        "show": true,
        "address": "",
        'address_hint': 'Here: user\'s jetton wallet address',
        "amount": 1,
        "cellToSend": "message",
        "cellCursor": "message",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "0": {
                    "type": "store",
                    "subType": "Uint",
                    "value": "now() + 5 * 60",
                    "bits": 32,
                    'hint': 'Specifies a deadline for the swap. If the swap reaches the Pool after this time, it will be rejected.'
                },
                "1": {
                    "type": "store",
                    "subType": "Address",
                    "value": "myAddress()",
                    'hint': 'Specifies an address where funds will be sent after the swap. Default: sender\'s address.'
                },
                "2": {"type": "store", "subType": "Uint", "value": "0", "bits": 2},
                "3": {"type": "store", "subType": "Bit", "value": "0"},
                "4": {"type": "store", "subType": "Bit", "value": "0"}
            },
            "body": {
                "0": {"type": "store", "subType": "Uint", "value": "0xe3a0d482", "bits": 32},
                "1": {
                    "type": "store",
                    "subType": "Address",
                    "value": "EQDcm06RlreuMurm-yik9WbL6kI617B77OrSRF_ZjoCYFuny",
                    'hint': 'Pool address. Here: TON/DUST pool'
                },
                "2": {"type": "store", "subType": "Uint", "value": "0", "bits": 1},
                "3": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "0",
                    'hint': 'Minimum output of the swap. If the actual value is less than specified, the swap will be rejected.'
                },
                "4": {"type": "store", "subType": "Bit", "value": "0"},
                "5": {"type": "store", "subType": "Ref", "value": "swap_params"}
            },
            "message": {
                "0": {"type": "store", "subType": "Uint", "value": "0x0f8a7ea5", "bits": 32},
                "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                "2": {"type": "store", "subType": "Coins", "value": "", 'hint': 'Jetton amount of the swap'},
                "3": {
                    "type": "store",
                    "subType": "Address",
                    "value": "EQAf4BMoiqPf0U2ADoNiEatTemiw3UXkt5H90aQpeSKC2l7f",
                    'hint': 'Jetton vault for $DUST'
                },
                "4": {"type": "store", "subType": "Address", "value": "myAddress()"},
                "5": {"type": "store", "subType": "Bit", "value": "0"},
                "6": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "toNano(0.15)",
                    'hint': 'Forward TON amount for the swap fee and gas'
                },
                "7": {"type": "store", "subType": "Bit", "value": "1"},
                "8": {"type": "store", "subType": "Ref", "value": "body"}
            }
        }
    },
    'dedustSwapPunkTon': {
        "name": "Swap PUNK to TON",
        "type": "dedust_swap_from_j",
        "jetton": "EQCdpz6QhJtDtm2s9-krV2ygl45Pwl-KJJCV1-XrP-Xuuxoq",
        'doclink': 'https://docs.dedust.io/recipes',
        "show": true,
        "address": "",
        'address_hint': 'Here: user\'s jetton wallet address',
        "amount": 1,
        "cellToSend": "message",
        "cellCursor": "message",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "0": {
                    "type": "store",
                    "subType": "Uint",
                    "value": "now() + 5 * 60",
                    "bits": 32,
                    'hint': 'Specifies a deadline for the swap. If the swap reaches the Pool after this time, it will be rejected.'
                },
                "1": {
                    "type": "store",
                    "subType": "Address",
                    "value": "myAddress()",
                    'hint': 'Specifies an address where funds will be sent after the swap. Default: sender\'s address.'
                },
                "2": {"type": "store", "subType": "Uint", "value": "0", "bits": 2},
                "3": {"type": "store", "subType": "Bit", "value": "0"},
                "4": {"type": "store", "subType": "Bit", "value": "0"}
            },
            "body": {
                "0": {"type": "store", "subType": "Uint", "value": "0xe3a0d482", "bits": 32},
                "1": {
                    "type": "store",
                    "subType": "Address",
                    "value": "EQDQdqlfuYhkgInJfHYV5KEI7YF4SPE2mduYiCrBIQeQXJWC",
                    'hint': 'Pool address. Here: TON/PUNK pool'
                },
                "2": {"type": "store", "subType": "Uint", "value": "0", "bits": 1},
                "3": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "0",
                    'hint': 'Minimum output of the swap. If the actual value is less than specified, the swap will be rejected.'
                },
                "4": {"type": "store", "subType": "Bit", "value": "0"},
                "5": {"type": "store", "subType": "Ref", "value": "swap_params"}
            },
            "message": {
                "0": {"type": "store", "subType": "Uint", "value": "0x0f8a7ea5", "bits": 32},
                "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                "2": {"type": "store", "subType": "Coins", "value": "", 'hint': 'Jetton amount of the swap'},
                "3": {
                    "type": "store",
                    "subType": "Address",
                    "value": "EQCnA5iADgj804vBKzyxVxxgIVUw0SPICA04_-o9bzdHOrI4",
                    'hint': 'Jetton vault for $punk'
                },
                "4": {"type": "store", "subType": "Address", "value": "myAddress()"},
                "5": {"type": "store", "subType": "Bit", "value": "0"},
                "6": {
                    "type": "store",
                    "subType": "Coins",
                    "value": "toNano(0.15)",
                    'hint': 'Forward TON amount for the swap fee and gas'
                },
                "7": {"type": "store", "subType": "Bit", "value": "1"},
                "8": {"type": "store", "subType": "Ref", "value": "body"}
            }
        }
    },
    'stonSwapTONPunk': {
        "name": "Swap TON to PUNK",
        "show": true,
        "address": "EQARZ1hF4v95ELsH7pCPMN79_UeqKOOgOjt8xrkW9HhIM-u1",
        "doclink": "https://docs.ston.fi/docs/developer-section/api-reference-v2/example_swap",
        'address_hint': 'Here: pTON jetton wallet address',
        'read_before': 'This is an example of swap on STONFI V2 from tonviewer and it works on 11/20/2024. There are no examples of onchain swaps without SDK in their docs, so things may change over time. Test it yourself',
        "amount": 5,
        "cellToSend": "body",
        "cellCursor": "payload",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "lines": {
                    "0": {"type": "store", "subType": "Coins", "value": "0", "hint": 'Minimum output of the swap.'},
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()",
                        "hint": "Receiver."
                    },
                    "2": {
                        "type": "store", "subType": "Coins", "value": "0",
                        "hint": "fwd_gas"
                    },
                    "3": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'Custom payload'},
                    "4": {"type": "store", "subType": "Coins", "value": "0", 'hint': 'refund_fwd_gas'},
                    "5": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'refund_payload'},
                    "6": {"type": "store", "subType": "Uint", "value": "0", "bits": 16, 'hint': 'ref_fee'},
                    "7": {"type": "store", "subType": "Uint", "value": "0", "bits": 2, 'hint': 'ref_address'}
                }
            },
            "payload": {
                "lines": {
                    "0": {
                        "type": "store", "subType": "Uint", "value": "0x6664de2a", "bits": 32,
                        'hint': 'Token transfer payload op for swap'
                    },
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "EQDkKz431j75-VhwxkoML25AQvVxik3rzB4Pwa6MdTzy2cus",
                        'hint': 'Here: stonfi_router_v2 jetton wallet address for this token'
                    },
                    "2": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'refund_address'},
                    "3": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'excesses_address'},
                    "4": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "now() + 5 * 60",
                        "bits": 64,
                        'hint': 'Deadline for the swap'
                    },
                    "5": {"type": "store", "subType": "Ref", "value": "swap_params", 'hint': 'Swap_params cell'}
                }
            },
            "body": {
                "lines": {
                    "0": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "0x1f3835d",
                        "bits": 32,
                        'hint': 'pTON transfer op'
                    },
                    "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                    "2": {"type": "store", "subType": "Coins", "value": "amount() - toNano(0.35)",},
                    "3": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()", 'hint': 'refund_address'
                    },
                    "4": {"type": "store", "subType": "Bit", "value": "1"},
                    "5": {"type": "store", "subType": "Ref", "value": "payload"}
                }
            }
        },
    },
    'stonSwapPunkTON': {
        "name": "Swap PUNK to TON",
        "type": "transfer_j",
        "jetton": "EQCdpz6QhJtDtm2s9-krV2ygl45Pwl-KJJCV1-XrP-Xuuxoq",
        "doclink": "https://docs.ston.fi/docs/developer-section/api-reference-v2/example_swap",
        "show": true,
        "address": "",
        "address_hint": "Here: user's jetton wallet address",
        'read_before': 'This is an example of swap on STONFI V2 from tonviewer and it works on 11/20/2024. There are no examples of onchain swaps without SDK in their docs, so things may change over time. Test it yourself',
        "amount": 0.3,
        "cellToSend": "body",
        "cellCursor": "payload",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "lines": {
                    "0": {"type": "store", "subType": "Coins", "value": "0", "hint": 'Minimum output of the swap.'},
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()",
                        "hint": "Receiver."
                    },
                    "2": {
                        "type": "store", "subType": "Coins", "value": "0", "hint": "fwd_gas"
                    },
                    "3": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'Custom payload'},
                    "4": {"type": "store", "subType": "Coins", "value": "0", 'hint': 'refund_fwd_gas'},
                    "5": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'refund_payload'},
                    "6": {"type": "store", "subType": "Uint", "value": "0", "bits": 16, 'hint': 'ref_fee'},
                    "7": {"type": "store", "subType": "Uint", "value": "0", "bits": 2, 'hint': 'ref_address'}
                }
            },
            "payload": {
                "lines": {
                    "0": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "0x6664de2a",
                        "bits": 32,
                        'hint': 'Token transfer payload op for swap'
                    },
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "EQARZ1hF4v95ELsH7pCPMN79_UeqKOOgOjt8xrkW9HhIM-u1",
                        "hint": 'pTON jetton wallet address for payout'
                    },
                    "2": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'refund_address'},
                    "3": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'excesses_address'},
                    "4": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "now() + 5 * 60",
                        "bits": 64,
                        'hint': 'Deadline for the swap'
                    },
                    "5": {"type": "store", "subType": "Ref", "value": "swap_params", 'hint': 'Swap_params cell'}
                }
            },
            "body": {
                "lines": {
                    "0": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "0x0f8a7ea5",
                        "bits": 32,
                        'hint': 'Token transfer op'
                    },
                    "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                    "2": {
                        "type": "store",
                        "subType": "Coins",
                        "value": "toNano(5)",
                        "hint": "Amount of transferred jettons in elementary units."
                    },
                    "3": {
                        "type": "store",
                        "subType": "Address",
                        "value": "EQAQYbnb1EGK0Wb8mk3vEW4vbHTyv7cOcfJlPWQ87_6_qfzR",
                        "hint": "Address of the new owner of the jettons. Here: stonfi_router_v2 address for this token"
                    },
                    "4": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()",
                        "hint": "Address where to send a response with confirmation of a successful transfer and the rest of the incoming message Toncoins."
                    },
                    "5": {"type": "store", "subType": "Bit", "value": "0"},
                    "6": {
                        "type": "store",
                        "subType": "Coins",
                        "value": "toNano(0.24)",
                        "hint": "The amount of nanotons to be sent to the destination address. Here: 0.24 TON to perform swap"
                    },
                    "7": {"type": "store", "subType": "Bit", "value": "1"},
                    "8": {"type": "store", "subType": "Ref", "value": "payload"}
                }
            }
        },
    },
    'stonSwapTONSpng': {
        "name": "Swap TON to SPNG",
        "show": true,
        "address": "EQDix2qMOc-QO05Nn9X7oKFnYTb3bvtxN7ySmzoGljrFv2bX",
        "doclink": "https://docs.ston.fi/docs/developer-section/api-reference-v2/example_swap",
        'address_hint': 'Here: stonfi_router_v2 jetton wallet address for pTON',
        'read_before': 'This is an example of swap on STONFI V2 from tonviewer and it works on 11/20/2024. There are no examples of onchain swaps without SDK in their docs, so things may change over time. Test it yourself',
        "amount": 5,
        "cellToSend": "body",
        "cellCursor": "payload",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "lines": {
                    "0": {"type": "store", "subType": "Coins", "value": "0", "hint": 'Minimum output of the swap.'},
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()",
                        "hint": "Receiver."
                    },
                    "2": {
                        "type": "store", "subType": "Coins", "value": "0",
                        "hint": "fwd_gas"
                    },
                    "3": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'Custom payload'},
                    "4": {"type": "store", "subType": "Coins", "value": "0", 'hint': 'refund_fwd_gas'},
                    "5": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'refund_payload'},
                    "6": {"type": "store", "subType": "Uint", "value": "0", "bits": 16, 'hint': 'ref_fee'},
                    "7": {"type": "store", "subType": "Uint", "value": "0", "bits": 2, 'hint': 'ref_address'}
                }
            },
            "payload": {
                "lines": {
                    "0": {
                        "type": "store", "subType": "Uint", "value": "0x6664de2a", "bits": 32,
                        'hint': 'Token transfer payload op for swap'
                    },
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "EQAuRcmrPqrSx141cDvfptnswQBtilI1LgBw92eiwflMd5c0",
                        'hint': 'Here: stonfi_router_v2 jetton wallet address for SPNG'
                    },
                    "2": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'refund_address'},
                    "3": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'excesses_address'},
                    "4": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "now() + 5 * 60",
                        "bits": 64,
                        'hint': 'Deadline for the swap'
                    },
                    "5": {"type": "store", "subType": "Ref", "value": "swap_params", 'hint': 'Swap_params cell'}
                }
            },
            "body": {
                "lines": {
                    "0": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "0x1f3835d",
                        "bits": 32,
                        'hint': 'pTON transfer op'
                    },
                    "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                    "2": {"type": "store", "subType": "Coins", "value": "amount() - toNano(0.35)",},
                    "3": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()", 'hint': 'refund_address'
                    },
                    "4": {"type": "store", "subType": "Bit", "value": "1"},
                    "5": {"type": "store", "subType": "Ref", "value": "payload"}
                }
            }
        },
    },
    'stonSwapSpngTON': {
        "name": "Swap SPNG to TON",
        "type": "transfer_j",
        "jetton": "EQCtWp_AbXaWQ_xztwXWir20k80Nw-PJo9EBEgHNgEpb2_2d",
        "doclink": "https://docs.ston.fi/docs/developer-section/api-reference-v2/example_swap",
        "show": true,
        "address": "",
        "address_hint": "Here: user's jetton wallet address",
        'read_before': 'This is an example of swap on STONFI V2 from tonviewer and it works on 11/20/2024. There are no examples of onchain swaps without SDK in their docs, so things may change over time. Test it yourself',
        "amount": 0.3,
        "cellToSend": "body",
        "cellCursor": "payload",
        "typeCursor": "store",
        "cells": {
            "swap_params": {
                "lines": {
                    "0": {"type": "store", "subType": "Coins", "value": "0", "hint": 'Minimum output of the swap.'},
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()",
                        "hint": "Receiver."
                    },
                    "2": {
                        "type": "store", "subType": "Coins", "value": "0", "hint": "fwd_gas"
                    },
                    "3": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'Custom payload'},
                    "4": {"type": "store", "subType": "Coins", "value": "0", 'hint': 'refund_fwd_gas'},
                    "5": {"type": "store", "subType": "Uint", "value": "0", "bits": 1, 'hint': 'refund_payload'},
                    "6": {"type": "store", "subType": "Uint", "value": "0", "bits": 16, 'hint': 'ref_fee'},
                    "7": {"type": "store", "subType": "Uint", "value": "0", "bits": 2, 'hint': 'ref_address'}
                }
            },
            "payload": {
                "lines": {
                    "0": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "0x6664de2a",
                        "bits": 32,
                        'hint': 'Token transfer payload op for swap'
                    },
                    "1": {
                        "type": "store",
                        "subType": "Address",
                        "value": "EQDix2qMOc-QO05Nn9X7oKFnYTb3bvtxN7ySmzoGljrFv2bX",
                        "hint": 'stonfi_router_v2 jetton wallet address for pTON'
                    },
                    "2": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'refund_address'},
                    "3": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'excesses_address'},
                    "4": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "now() + 5 * 60",
                        "bits": 64,
                        'hint': 'Deadline for the swap'
                    },
                    "5": {"type": "store", "subType": "Ref", "value": "swap_params", 'hint': 'Swap_params cell'}
                }
            },
            "body": {
                "lines": {
                    "0": {
                        "type": "store",
                        "subType": "Uint",
                        "value": "0x0f8a7ea5",
                        "bits": 32,
                        'hint': 'Token transfer op'
                    },
                    "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                    "2": {
                        "type": "store",
                        "subType": "Coins",
                        "value": "toNano(5)",
                        "hint": "Amount of transferred jettons in elementary units."
                    },
                    "3": {
                        "type": "store",
                        "subType": "Address",
                        "value": "EQDh5oHPvfRwPu2bORBGCoLEO4WQZKL4fk5DD1gydeNG9oEH",
                        "hint": "Address of the new owner of the jettons. Here: stonfi_router_v2 jetton wallet address for SPNG"
                    },
                    "4": {
                        "type": "store",
                        "subType": "Address",
                        "value": "myAddress()",
                        "hint": "Address where to send a response with confirmation of a successful transfer and the rest of the incoming message Toncoins."
                    },
                    "5": {"type": "store", "subType": "Bit", "value": "0"},
                    "6": {
                        "type": "store",
                        "subType": "Coins",
                        "value": "toNano(0.24)",
                        "hint": "The amount of nanotons to be sent to the destination address. Here: 0.24 TON to perform swap"
                    },
                    "7": {"type": "store", "subType": "Bit", "value": "1"},
                    "8": {"type": "store", "subType": "Ref", "value": "payload"}
                }
            }
        },
    }, 'BigPumpBuy':
        {
            "name": "Buy on BigPump",
            "show": true,
            "address": "",
            'doclink': 'https://github.com/ton-fun-tech/ton-bcl-sdk/blob/main/src/wrappers/BclJetton.ts#L267C11-L267C18',
            'address_hint': 'Jetton address (master contract)',
            "amount": 1,
            "cellToSend": "body",
            "cellCursor": "body",
            "typeCursor": "store",
            "cells": {
                "body": {
                    "0": {"type": "store", "subType": "Uint", "value": "0xaf750d34", "bits": 32},
                    "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                    "2": {"type": "store", "subType": "Coins", "value": "toNano(0)", 'hint': 'Minimum output of the swap in tokens.'},
                    "3": {"type": "store", "subType": "Bit", "value": "0", 'hint': 'MaybeRef(opts.referral)'},
                    "4": {"type": "store", "subType": "Address", "value": "myAddress()", 'hint': 'Buyer address'}
                }
            }
        },
    'BigPumpSell':
        {
            "name": "Sell on BigBump",
            "show": true,
            "address": "",
            'address_hint': 'User\'s jetton wallet address',
            'doclink': 'https://github.com/ton-fun-tech/ton-bcl-sdk/blob/main/src/wrappers/BclWallet.ts#L39',
            "amount": 0.06,
            "cellToSend": "body",
            "cellCursor": "body",
            "typeCursor": "store",
            "cells": {
                "body": {
                    "0": {"type": "store", "subType": "Uint", "value": "0x742b36d8", "bits": 32},
                    "1": {"type": "store", "subType": "Uint", "value": "0", "bits": 64},
                    "2": {"type": "store", "subType": "Coins", "value": "toNano(1)", 'hint': 'Amount in tokens'},
                    "3": {"type": "store", "subType": "Coins", "value": "toNano(0)", 'hint': 'Minimum output of the swap in TON.'},
                    "4": {"type": "store", "subType": "Bit", "value": "0"}
                }
            }
        },
}

export default templates;