import {Address, TonClient4} from "@ton/ton";
import {getHttpV4Endpoint} from "@orbs-network/ton-access";
import DOMPurify from "dompurify";

export default {
    methods: {
        sanitizeObject(obj) {
            if (typeof obj === "string") {
                return DOMPurify.sanitize(obj);
            }
            if (Array.isArray(obj)) {
                return obj.map(this.sanitizeObject);
            }
            if (typeof obj === "object" && obj !== null) {
                return Object.fromEntries(
                    Object.entries(obj).map(([key, value]) => [key, this.sanitizeObject(value)])
                );
            }
            return obj;
        },
        async runContractMethod(address, method, args) {
            const endpoint = this.$store.getters.getEndpoint
            const client = new TonClient4({
                endpoint
            });
            let lastBlock = await this.getLastBlock()
            const result = await client.runMethod(
                lastBlock.last.seqno,
                Address.parse(address),
                method,
                args
            );
            return result;
        },
        async getLastBlock() {
            let endpoint = this.$store.getters.getEndpoint
            if (endpoint == null) await this.setEndpoint();
            endpoint = this.$store.getters.getEndpoint
            const client = new TonClient4({
                endpoint
            });
            let lastBlock = await client.getLastBlock();
            let seqno = lastBlock.last.seqno;
            this.$store.dispatch('setSeqno', seqno)
            return lastBlock;
        },
        async setEndpoint() {
            const endpoint = await getHttpV4Endpoint({
                network: this.$store.getters.getMode,
            });
            this.$store.dispatch('setEndpoint', endpoint)
            return endpoint
        },
        async getNftIndex(domainName) {
            const normalizedName = this.tryNormalizeName(domainName);
            if (!normalizedName) {
                throw new RangeError("Invalid domain name.");
            }

            // UTF-8 encoded string up to 126 bytes
            const encoder = new TextEncoder();
            const bytes = encoder.encode(normalizedName);
            if (bytes.length > 126) {
                throw new RangeError("Value is too long. No more than 126 chars are allowed.");
            }

            // Create hash source buffer
            const hashSource = new Uint8Array(bytes.length + 2);
            hashSource[0] = 0;
            hashSource[1] = bytes.length * 2;
            hashSource.set(bytes, 2);

            // Compute SHA-256 hash
            const hashBuffer = await crypto.subtle.digest("SHA-256", hashSource);
            const arr = Array.from(new Uint8Array(hashBuffer))
                            .map(byte => byte.toString(16).padStart(2, '0'))
                            .join('');
            return '0x' + arr;
        },
        tryNormalizeName(domainName) {
            if (typeof domainName !== 'string' || !domainName.trim()) {
                return null;
            }

            const parts = domainName.trim().toLowerCase().split('.').filter(Boolean);

            if (parts.length > 2) {
                return null;
            }

            if (parts.length === 2 && parts[1] !== "ton") {
                return null;
            }

            return parts[0];
        }

    },
    mounted() {
        this.$watch(
            () => this.$store.getters.getMode,
            (newValue, oldValue) => {
                console.log('mode changed', oldValue, newValue)
                this.setEndpoint()
            }
        );
    }
};