import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/shared/:sharedKey',
        name: 'shared',
        component: HomeView,
        meta: {title: 'Act'}
    },
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {title: 'Act'}
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to) => {

    document.title = to.meta?.title ?? 'Act'

})
export default router
